import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function useRecaptcha(): { recaptcha: string } {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptcha, setRecaptcha] = useState('');

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return;
    const token = await executeRecaptcha();
    setRecaptcha(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return {
    recaptcha,
  };
}
