import React, { ReactElement } from 'react';
import classes from './styles.module.scss';

interface Props {
  totalItems: number
  currentPage: number
  pageSize?: number
  maxPages?: number
  pageChanged: (page: number) => void
}

const defaultProps = {
  pageSize: 6,
  maxPages: 5,
};

function Ellipsis() {
  return <div className={`disabled ${classes.ellipsis}`}>...</div>;
}

function DynamicPagination({
  totalItems,
  currentPage = 1,
  pageSize = 10,
  maxPages = 5,
  pageChanged,
}: Props): ReactElement {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);
  let page = currentPage;

  // ensure current page isn't out of range
  if (currentPage < 1) {
    page = 1;
  } else if (currentPage > totalPages) {
    page = totalPages;
  }

  let startPage: number;
  let endPage: number;

  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (page <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (page + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = page - maxPagesBeforeCurrentPage;
      endPage = page + maxPagesAfterCurrentPage;
    }
  }

  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array(endPage + 1 - startPage).keys())
    .map((i) => startPage + i)
    .filter((p) => p !== 1 && p !== totalPages);

  const prev = () => {
    if (page < 2) return;
    pageChanged(page - 1);
  };

  const next = () => {
    if (page === totalPages) return;
    pageChanged(page + 1);
  };

  return (
    <div className="flex align-center justify-center" style={{ gap: '0.5rem' }}>
      {page > 1 && (
        <button type="button" onClick={prev} className={classes.item}>
          <i className="fas fa-angle-left" />
        </button>
      )}

      <button type="button" onClick={() => pageChanged(1)} className={`${classes.item} ${page === 1 ? classes.active : ''}`}>
        1
      </button>

      {totalPages > maxPages && page >= maxPages && <Ellipsis />}

      {pages.map((_page) => (
        <button
          type="button"
          key={_page}
          onClick={() => pageChanged(_page)}
          className={`${classes.item} ${_page === currentPage ? classes.active : ''}`}
        >
          {_page}
        </button>
      ))}

      {totalPages > maxPages && page <= totalPages - maxPages + 1 && (
        <Ellipsis />
      )}

      <button type="button" onClick={() => pageChanged(totalPages)} className={`${classes.item} ${page === totalPages ? classes.active : ''}`}>
        {totalPages}
      </button>

      {page < totalPages && (
        <button type="button" onClick={next} className={classes.item}>
          <i className="fas fa-angle-right" />
        </button>
      )}
    </div>
  );
}

DynamicPagination.defaultProps = defaultProps;

export default DynamicPagination;
