import type { NextPageContext } from 'next';
import {
  ChangeEvent, Fragment, KeyboardEvent, ReactElement, useEffect, useRef, useState,
} from 'react';
import { useRouter } from 'next/router';

import DynamicPagination from '@/components/DynamicPagination';
import Loading from '@/components/Loading';
import PageHead from '@/components/PageHead';
import ProjectsList from '@/components/project/ProjectsList';
import MostViewedPublications from '@/components/publication/MostViewed';
import RegisterCTA from '@/components/RegisterCTA';
import Search from '@/components/svg/icons/Search';
import useAuthenticatedUser from '@/hooks/useAuthenticatedUser';
import useTranslation from '@/hooks/useTranslation';
import { searchProjectToProjectResume } from '@/lib/publication';
import { getScreenWidth } from '@/lib/screen';
import { fetchUserSSR } from '@/services/user';
import PublicationService from '@/services/publication';
import MainLayout from 'layouts/Main';
import dynamic from 'next/dynamic';
import classes from './index-styles.module.scss';

const RecatchaProvider = dynamic(() => import('@/components/RecaptchaProvider'), { ssr: false });

const perPage = 10;

function Home(): ReactElement {
  const router = useRouter();
  const [term, setTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const [total, setTotal] = useState<number | null>(null);
  const [projects, setProjects] = useState<ProjectResume[] | null>(null);
  const screenWidth = getScreenWidth();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const user = useAuthenticatedUser();

  const { t } = useTranslation();

  const executeScroll = () => {
    if (!inputRef || !inputRef.current) return;
    inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const search = async (_term?: string, _page?: number) => {
    setLoading(true);
    setNotFound(false);
    const publications = await PublicationService.searchPublications(
      { term: _term || term, page: _page || page, perPage },
    );
    setTotal(publications.total || null);
    if (!publications.total) setNotFound(true);
    if (!publications.projects) return setProjects([]);
    setProjects(searchProjectToProjectResume(publications.projects));
    setLoading(false);
    executeScroll();
  };

  useEffect(() => {
    if (!router.query || !router.query.term) return;
    const _term = router.query.term.toString();
    const _page = router.query.page ? parseInt(router.query.page.toString(), 10) : 1;
    setTerm(_term);
    setPage(_page);
    search(_term, _page);
  }, [router.query]);

  const termChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  const baseUrl = () => {
    const { location } = window;
    const { origin, pathname } = location;
    return origin + pathname;
  };

  const searchBtnClicked = () => {
    if (!term.length) return;
    setPage(1);
    router.push(`${baseUrl()}?term=${term}`);
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    searchBtnClicked();
  };

  const pageChanged = (newPage: number) => {
    router.push(`${baseUrl()}?term=${term}&page=${newPage}`);
  };

  return (
    <MainLayout>
      <Fragment>
        <RecatchaProvider>
          <PageHead title={t('header.title')} description={t('header.description')} image={process.env.NEXT_PUBLIC_LOGO_URL} />
          <section className={classes.section}>
            <div className="container flex flex-col items-center color-white">
              <h1 className={`color-white ${classes.title}`}>{t('search.mainTitle')}</h1>
              <p className={`text-center mt-0 mb-6 text-mine-shaft2 font-semibold tracking-wide md:mb-10 ${classes.paragraph}`}>{t('search.subTitle')}</p>
              <div ref={inputRef} className="w-full" style={{ height: '1rem' }} />
              <div className="flex justify-center w-full">
                <input type="text" value={term} onChange={termChanged} className={`mr-2 ${classes.input}`} onKeyPress={handleEnter} placeholder={t('search.onMettzer')} />
                <button type="button" aria-label="Buscar" className={`flex justify-center items-center ${classes.searchBtn}${loading ? ' disabled' : ''}`} onClick={searchBtnClicked}>{loading ? <Loading /> : <Search />}</button>
              </div>
            </div>
          </section>
          <div className="relative">
            <section className={`container flex justify-center ${classes.pageBottom}`}>

              {loading ? (
                <Fragment>
                  <div className="mt-5" />
                  <div className={classes.loadingBox}>
                    <Loading text={`${t('search.searchingPublications')}...`} />
                  </div>
                </Fragment>
              ) : (
                <div className={`mb-2 ${classes.publicationsContainer}`}>
                  {total !== null && (
                  <p className="fontsize-14">
                    {t('search.searchInfo', {
                      page,
                      total,
                      count: total,
                    })}
                  </p>
                  )}
                  {projects && projects.length > 0 && (
                  <ProjectsList
                    projects={projects}
                    resumeMaxLen={screenWidth > 600 ? 370 : 230}
                  />
                  )}
                  {total && total > perPage && (
                  <DynamicPagination
                    totalItems={total}
                    currentPage={page}
                    pageChanged={pageChanged}
                    pageSize={perPage}
                  />
                  )}
                  {notFound && <p><strong>{t('search.searchNotFound')}</strong></p>}
                  <MostViewedPublications />
                </div>
              )}
            </section>
            {!user && (
            <div className="lg:absolute h-full lg:w-15 lg:top-0 lg:right-4 ">

              <RegisterCTA className={classes.ctaCard} />

            </div>
            )}
            <div className="p-4" />
          </div>
        </RecatchaProvider>
      </Fragment>
    </MainLayout>
  );
}

export async function getServerSideProps(
  { req, res }: NextPageContext,
): Promise<any> {
  if (req && res) {
    await fetchUserSSR(req, res);

    res.setHeader(
      'Cache-Control',
      'public, s-maxage=60, stale-while-revalidate=240',
    );
  }

  return {
    props: {},
  };
}

export default Home;
