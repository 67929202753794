export function searchProjectToProjectResume(projects: SearchProject[]): ProjectResume[] {
  return projects.map((project) => {
    const {
      _id = '', title = '', summaryRawText = '', year = '', url = '',
    } = project;

    return {
      _id,
      title,
      resume: summaryRawText,
      year,
      url,
    };
  });
}

export default {};
