import React, {
  CSSProperties,
  ReactElement,
} from 'react';

import useTranslation from '@/hooks/useTranslation';
import useRecaptcha from '@/hooks/useRecaptcha';
import classes from './styles.module.scss';

interface Props {
  className: string
  style?: CSSProperties
}

const registerUrl = `${process.env.NEXT_PUBLIC_AUTH_URL}/register?met_trial_origin=discovery&utm_source=discovery&met_location=${process.env.NEXT_PUBLIC_DISCOVERY_URL}`;

export default function RegisterCTA({ className, style = {} }: Props): ReactElement {
  const { recaptcha } = useRecaptcha();
  const { t } = useTranslation();

  return (
    <div className={`${classes.card} ${className}`} style={style}>
      <h2 className={classes.title}>{t('register.ctaTitle')}</h2>
      <form action={registerUrl} method="post" className="mt-6 2xl:mt-10">
        <input required id="email" name="email" type="text" className={`max-w-full ${classes.input}`} placeholder={t('register.ctaInputPlaceholder')} />
        <input type="hidden" name="recaptcha" value={recaptcha} />
        <div className="my-4 md:my-6 lg:my-4">
          <button type="submit" className={`button w-full ${classes.button}`}>{t('register.tryForFree')}</button>
        </div>
      </form>
      <div className="flex mt-4" style={{ gap: '2rem' }}>
        <ul className={classes.list}>
          <li>
            <i className="fas fa-check mr-1" />
            {t('register.features.textEditor')}
          </li>
          <li className="mt-3">
            <i className="fas fa-check mr-1" />
            {t('register.features.referencesGenerator')}
          </li>
        </ul>
        <ul className={classes.list}>
          <li>
            <i className="fas fa-check mr-1" />
            {t('register.features.researchAndManagement')}
          </li>
          <li className="mt-3">
            <i className="fas fa-check mr-1" />
            {t('register.features.workModels')}
          </li>
        </ul>
      </div>
    </div>
  );
}

RegisterCTA.defaultProps = {
  style: {},
};
